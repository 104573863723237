
import {Component, Ref, Vue} from 'vue-property-decorator';
import PriceCard from '@/views/motorcycle/common/PriceCard.vue';
import BaseLayout from '@/views/motorcycle/BaseLayout.vue';
import IPriceListData from '@/dataDescriptor/IPriceListData';

@Component<SportGlide>({
    components: {BaseLayout, PriceCard},
    metaInfo()
    {
        return {
            title: 'Sport Glide',
            titleTemplate: '%s | Půjčovna Haryků',
            meta: [
                {property: 'og:title', content: 'Sport Glide | Půjčovna Haryků'},
                {property: 'og:type', content: 'article'},
                {property: 'og:url', content: 'https://www.pujcovna-haryku.cz'},
                {property: 'og:description', content: 'Jsme nadšenci do motocyklů a speciálně do značky Harley Davidson, chceme svoje stroje a radost z jízdy sdílet s ostatními. Proto jsme na jaře 2019 založili malou půjčovnu motocyklů v Pardubicích.'},
                {property: 'og:image', content: 'https://www.pujcovna-haryku.cz' + this.getHeroImages[0] },
                {property: 'twitter:image:src', content: 'https://www.pujcovna-haryku.cz' + this.getHeroImages[0] },
                {property: 'og:image:width', content: '1000'},
                {property: 'og:site_name', content: 'Půjčovna Haryků'},
            ],
        };
    },
})
export default class SportGlide extends Vue
{
    get getMotorcycleAttributes(): object
    {
        return {
            'Řidičské oprávnění': 'A',
            'Hmotnost': '317 kg',
            'Výkon': '62 KW',
            'Točivý moment': '145 NM',
            'Počet míst': '2',
        };
    }

    get getPriceLists(): IPriceListData[]
    {
        return [
            {name: 'Půl den', description: 'Platí i o víkendu', price: 1800},
            {name: 'Den', description: 'Platí i o víkendu', price: 3000},
            {name: 'Víkend', description: 'Převezmu v Pátek.\n' + 'Vrátím v Pondělí.', price: 7000},
        ];
    }

    get getHeroImages(): string[]
    {
        return [
            require('../../assets/images/motorcycle/hero_sport_glide.jpg'),
        ];
    }
}
